<template>
  <div class="permission">
    <v-row style="margin-top:40px;font-size:32px;">
      <v-col cols="4">
        <p>
          權限設定
        </p>
      </v-col>
      <v-spacer />
      <v-col cols="2">
        <v-btn
          color="#4DBCB8"
          style="color:white;font-size:20px;float:right;"
          :disabled="$store.state.userInfo.position !== 'admin'"
          @click="dialog = true"
        >
          邀請夥伴
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="$store.state.members"
      class="elevation-1"
      style="font-size:18px;height:100%;overflow:scroll;"
      height="580"
      :items-per-page="-1"
      @dblclick:row="disableAccount"
    >
      <template v-slot:item.member="{ item }">
        <v-row style="font-size:18px;margin:0px;">
          <v-col cols="3" style="margin:0px;text-align:center;">
            <div
              @click="onImage(item)"
            >
              <v-img
                v-if="item.image != undefined"
                style="border-radius: 50%;cursor:pointer;"
                :src="item.image"
                :aspect-ratio="1/1"
              >
              </v-img>
              <span v-else>
                <v-icon style="font-size: 100px;color: rgba(255,0,0,.3)" v-if="item.deactived">
                  mdi-cancel
                </v-icon>
                <v-img
                  v-else
                  style="border-radius: 50%;cursor:pointer;color:#4DBCB8;"
                  src="../assets/blank.svg"
                  :aspect-ratio="1/1"
                >
                </v-img>
              </span>
            </div>
            <v-file-input
              ref="uploader"
              v-show="false"
              v-model="image"
              filled
              hide-input
              :loading="load"
              style="color:#4DBCB8;font-size:50px;"
              prepend-icon="mdi-account-circle"
              :disabled="$store.state.userInfo.position !== 'admin'"
              @change="uploadAvator(item)"
            >
            </v-file-input>
          </v-col>
          <v-col cols="9" style="margin:0px;">
            <div>
              {{ item.name }}
            </div>
            <div style="opacity:0.5">
              {{ item.email }}
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.position="{ item }">
        <v-select
          style="padding-top:30px;margin:0px;"
          v-model="item.position"
          :items="positions"
          item-text="label"
          item-value="value"
          dense
          outlined
          return-object
          :disabled="$store.state.userInfo.position !== 'admin' || item.deactived"
          @change="updateToCloud(item)"
        ></v-select>
      </template>
      <template v-slot:item.color="{ item }">
        <v-menu :close-on-content-click="closeOnContentClick">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="item.color"
              fab
              dark
              small
              :disabled="item.deactived"
              elevation="0"
              v-bind="attrs"
              v-on="on"
            >
            </v-btn>
          </template>
          <v-color-picker
            dot-size="25"
            swatches-max-height="100"
            show-swatches
            mode="rgba"
            @input="pickItem(item)"
            @update:color="pickColor"
          ></v-color-picker>
        </v-menu>
      </template>
      <template v-slot:item.region="{ item }">
        <v-select
          style="padding-top:30px;margin:0px;"
          v-model="item.region"
          :items="$store.state.areas"
          item-text="name"
          item-value="name"
          dense
          outlined
          :disabled="$store.state.userInfo.position !== 'admin' || item.deactived"
          @change="updateToCloud(item)"
        ></v-select>
      </template>
      <template v-slot:item.ability="{ item }">
        <!-- {{item.ability}} -->
        <v-select
          style="padding-top:30px;margin:0px;width:200px;"
          v-model="item.ability"
          :items="$store.state.abilities"
          dense
          item-text="name"
          item-value="name"
          return-object
          multiple
          outlined
          readonly
          :disabled="$store.state.userInfo.position !== 'admin' || item.deactived"
          @focus="taskChoose($event, item)"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0" style="max-width:120px;">
              <span>{{ item.name }}</span>
            </v-chip>
            <span
              v-if="index === 1"
            >
              ...
            </span>
          </template>
        </v-select>
      </template>
    </v-data-table>
    <v-dialog
      v-model="selectDialog"
      width="600"
      @click:outside="updateToCloud(currentPerson)"
    >
     <v-card style="height:400px;padding:8px;overflow:scroll">
       <v-treeview
        v-model="abilityArray"
        selectable
        @input="taskSelect"
        :items="$store.state.taskCategories">
       </v-treeview>
     </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog"
      width="700"
    >
     <v-card style="height:300px;padding:8px;">
       <v-row>
         <v-col cols="4">
           <v-text-field
            v-model="name"
            label="姓名"
            outlined
            color="#4DBCB8"
           />
         </v-col>
         <v-col cols="6">
           <v-text-field
            v-model="email"
            label="email"
            outlined
            color="#4DBCB8"
           />
         </v-col>
         <v-col cols="2">
           <v-btn
            dark
            color="#4DBCB8"
            style="height:55px;"
            @click="invite"
          >
             <span style="font-size:30px;">+</span>
           </v-btn>
         </v-col>
       </v-row>
       <div v-show="invites.length > 0">已邀請</div>
       <div
        style="color:grey"
        v-for="invite in invites"
        :key="invite">
        姓名：{{invite.name}}, 電子信箱：{{invite.email}}
      </div>
     </v-card>
    </v-dialog>
    <v-dialog v-model="disableDialog" width="320">
      <v-card style="overflow:hidden">
        <v-card-title style="color:#BC5156">
          <span>
            確認刪除帳號：「{{currentPerson.name}}」 ？
          </span>
        </v-card-title>
        <v-card-actions>
          <v-row>
            <v-spacer />
            <v-col cols="5">
              <v-btn
                style="width:100%"
                color="#BC5156"
                outlined
                @click="confirmDisable"
              >
                刪除
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col cols="5">
              <v-btn
                style="width:100%"
                color="grey"
                outlined
                @click="disableDialog = false"
              >
                取消
              </v-btn>
            </v-col>
            <v-spacer />
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '@/api/permission';
import { db } from '../db';
// @ is an alias to /src

export default {
  name: 'Permission',
  components: {

  },
  data: () => ({
    disableDialog: false,
    tempArr: [],
    abilityArray: [],
    currentPerson: {},
    tree: [],
    selectDialog: false,
    currentAccount: {},
    image: null,
    load: false,
    invites: [],
    name: '',
    email: '',
    dialog: false,
    closeOnContentClick: true,
    abilities: [],
    currentItem: {},
    items: [],
    // items: ['北區', '桃竹', '中區', '南區', '淨水', '大水', '總部'],
    positions: [
      {
        value: 'admin',
        label: '系統管理員',
      }, {
        value: 'director',
        label: '主管',
      }, {
        value: 'manager',
        label: '內勤',
      }, {
        value: 'viewer',
        label: '業務',
      }, {
        value: 'general',
        label: '師傅',
      },
    ],
    headers: [
      {
        text: '成員', value: 'member', width: '40%', align: 'center',
      },
      {
        text: '權限', value: 'position', width: '15%', align: 'center',
      },
      {
        text: '代表色', value: 'color', width: '15%', align: 'center',
      },
      {
        text: '區域', value: 'region', width: '15%', align: 'center',
      },
      {
        text: '技能', value: 'ability', width: '15%', align: 'center',
      },
    ],
  }),
  mounted() {
    if (this.$store.state.members.length === 0) {
      db.collection('companies').doc('hsH43NOpBi9XSG3YeU1g').collection('accounts')
        .onSnapshot((snapshot) => {
          snapshot.forEach(async (change) => {
            let check = this.$store.state.members.find((ele) => ele.id === change.data().id);
            if (check) {
              check = change.data();
            } else {
              this.$store.state.members.push(change.data());
            }
          });
        });
    }
    // this.getFromGoogle();
  },
  computed: {
  },
  methods: {
    confirmDisable() {
      api.accountDisable({ id: this.currentPerson.id })
        .then(async ({ data }) => {
          if (data.success) {
            const removeIndex = this.$store.state.members
              .findIndex((ele) => ele.id === this.currentPerson.id);
            this.$store.state.members.splice(removeIndex, 1);
            // this.$store.state.members = newMember;
            this.disableDialog = false;
            console.log(data, 'account deleted');
          } else {
            console.log('err:', data);
          }
        });
      console.log(this.currentPerson, 'ccccDDDD');
    },
    disableAccount(e, obj) {
      if (this.$store.state.userInfo.position === 'admin') {
        this.disableDialog = true;
        this.currentPerson = obj.item;
        console.log(e, obj.item, 'DDDDDDDD');
      }
    },
    async taskChoose(e, member) {
      this.selectDialog = true;
      this.currentPerson = await member;
      e.target.blur();
      await console.log(this.currentPerson, 'TTTTTTT');
      if (this.currentPerson.ability === undefined) {
        this.$set(this.currentPerson, 'ability', []);
      } else {
        this.currentPerson.ability.forEach((ele) => {
          this.abilityArray.push(ele.time);
        });
      }
    },
    async taskSelect() {
      await this.abilityArray.forEach((ele) => {
        this.loopSelect(this.$store.state.taskCategories, ele);
      });
      await this.tempArr.filter((item, index) => this.tempArr.indexOf(item.id) === index);
      await this.$set(this.currentPerson, 'ability', JSON.parse(JSON.stringify(this.tempArr)));
      this.tempArr = await [];
    },
    loopSelect(tree, id) {
      tree.forEach((ele) => {
        if (ele.id === id) {
          this.tempArr.push(ele);
        }
        if (ele.children.length > 0) {
          this.loopSelect(ele.children, id);
        } else {
          console.log('do nothing');
        }
      });
    },
    getFromGoogle() {
      fetch('https://docs.google.com/spreadsheets/d/1-5O2O7TQo_caUxG6INsp0xNykl3Imhhvqb-Zu37oHhQ/gviz/tq?tqx=out:json&tq&gid=800043933')
        .then((res) => res.text())
        .then(async (text) => {
          const json = JSON.parse(text.substr(47).slice(0, -2));
          // const myJson = JSON.parse(text)
          // console.log(json, '~~~~~~~~~~');
          const newData = [];
          const newKey = [];
          await json.table.rows[0].c.forEach((element) => {
            newKey.push(element.v);
          });
          await json.table.rows.forEach(async (ele, index) => {
            if (index !== 0) {
              // await newData.push({});
              const newObj = {};
              // console.log(ele, 'OOOOOOOOO');
              await ele.c.forEach((el, index) => {
                const key = newKey[index];
                if (index === 0) {
                  newObj[key] = el.v;
                } else if (index === 1) {
                  newObj[key] = el.v;
                } else {
                  console.log('read google sheet table wrong');
                }
              });
              newObj.color = '#FF0000';
              newObj.password = 'shihlei';
              newObj.position = {
                value: 'general',
                label: '師傅',
              };
              await newData.push(newObj);
            }
          });
          newData.forEach((ele) => {
            const check = this.$store.state.members.some((el) => el.name === ele.name);
            if (!check) {
              this.$store.state.members.push(ele);
            }
            console.log('^^^^^', this.$store.state.members.length);
            console.log('+++++', ele.name, check);
          });
          // this.$set(this.$store.state, 'members', newData);
        });
    },
    onImage(item) {
      this.$refs.uploader.$refs.input.click(item);
      this.currentAccount = item;
      console.log('fire mmmmmmm');
    },
    async invite() {
      const newAccount = {
        name: this.name,
        email: this.email,
      };
      this.invites.push(newAccount);
      api.accountCreate(newAccount)
        .then(async ({ data }) => {
          if (data.success) {
            this.name = await '';
            this.email = await '';
            console.log(data, 'create account ok');
          } else {
            console.log('err:', data);
          }
        });
    },
    pickItem(item) {
      this.$set(this, 'currentItem', item);
      console.log('itemmm', item);
    },
    pickColor(color) {
      this.$set(this.currentItem, 'color', color.hex);
      api.permissionUpdate(this.currentItem)
        .then(async ({ data }) => {
          if (data.success) {
            console.log(data);
          } else {
            console.log('err:', data);
          }
        });
      console.log('picker', color);
    },
    readTaskFromTasks(array) {
      const arr = [];
      array.forEach((ele) => {
        if (ele.children.length > 0) {
          this.readTaskFromTasks(ele.children);
        } else {
          this.abilities.push(ele.name);
        }
      });
      return arr;
    },
    updateToCloud(content) {
      console.log(content, 'update to cloud');
      api.permissionUpdate(content)
        .then(async ({ data }) => {
          if (data.success) {
            this.$set(this, 'abilityArray', []);
            this.$set(this, 'currentPerson', {});
            console.log(data);
          } else {
            console.log('err:', data);
          }
        });
    },
    async uploadAvator() {
      console.log('avator upload', this.currentAccount);
      const formData = new FormData();
      this.loading = true;
      await formData.append('file', this.image);
      await formData.append('id', this.currentAccount.id);
      api.uploadAvator(formData).then(({
        data,
      }) => {
        if (data.success) {
          this.image = null;
          this.loading = false;
          window.location.reload();
          console.log('img upload');
          // })
        } else {
          console.log('upload error');
        }
      }).catch((err) => {
        console.log(err);
      });
    },
  },
};
</script>
